// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-canal-de-conduta-etica-js": () => import("./../../../src/pages/canal-de-conduta-etica.js" /* webpackChunkName: "component---src-pages-canal-de-conduta-etica-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mensagem-recebida-js": () => import("./../../../src/pages/mensagem-recebida.js" /* webpackChunkName: "component---src-pages-mensagem-recebida-js" */),
  "component---src-pages-qualidade-js": () => import("./../../../src/pages/qualidade.js" /* webpackChunkName: "component---src-pages-qualidade-js" */),
  "component---src-pages-servicos-js": () => import("./../../../src/pages/servicos.js" /* webpackChunkName: "component---src-pages-servicos-js" */),
  "component---src-pages-sobre-nos-js": () => import("./../../../src/pages/sobre-nos.js" /* webpackChunkName: "component---src-pages-sobre-nos-js" */)
}

